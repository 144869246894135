import { Component, OnDestroy, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter, firstValueFrom, timer } from 'rxjs';
import { resetClient } from '../utils';

@Component({
  selector: 'lib-callback',
  template: `
    <mat-spinner></mat-spinner>
    @if (shouldDisplay()) {
      <button mat-stroked-button (click)="hardReload()">
        <div class="button-label">
          <mat-icon>refresh</mat-icon>
          <span>{{ 'Reload' | translate }}</span>
        </div>
      </button>
    }
  `,
  styles: [
    `
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 2rem;

        button {
          animation: fadeIn 0.5s ease-in-out;
          margin-bottom: -3.53rem;
        }
        .button-label {
          display: flex;
          place-items: center;
          gap: 0.5rem;
        }
      }
    `,
  ],
})
export class CallbackComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  shouldDisplay = signal(false);

  constructor(private router: Router) {
    firstValueFrom(this.router.events.pipe(filter(e => e instanceof NavigationEnd))).then(evt => {
      if (evt instanceof NavigationEnd) {
        if (evt.urlAfterRedirects.startsWith('/callback')) {
          this.router.navigate(['/']);
        }
      }
    });
    this.subscriptions.push(timer(8000).subscribe(() => this.shouldDisplay.set(true)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  async hardReload() {
    resetClient().then(() => {
      // Reload
      const url = location.href;
      location.href = url.substring(0, url.indexOf('/callback') > -1 ? url.indexOf('/callback') : url.length);
    });
  }
}
