import { Component, OnInit, WritableSignal, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '.';
import { AppService } from '../app.service';
import { isValidEmail } from '../form';
import { titleCase } from '../utils';
import { getLocalStorage } from '../utils/getLocalStorage';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'login-form' },
})
export class LoginComponent implements OnInit {
  email = new FormControl('');
  applicationName = this.app.getApplicationName();
  lastUser: WritableSignal<string | undefined> = signal(undefined);

  constructor(
    private auth: AuthService,
    private app: AppService,
  ) {}

  ngOnInit(): void {
    const lastUser = getLocalStorage().getItem('user') ?? undefined;
    if (lastUser && isValidEmail(lastUser)) {
      this.lastUser.set(lastUser);
      this.email.setValue(this.lastUser()!);
    }
  }

  reset() {
    this.lastUser.set(undefined);
    this.email.reset();
  }

  login() {
    const user = this.email.value as string;
    if (user && isValidEmail(user)) {
      const domain = user.split('@')[1].split('.')[0].toLowerCase();
      const params = new URLSearchParams(window.location.search);
      const returnTo = params.has('returnTo') ? params.get('returnTo') : undefined;
      this.auth.login({ ...(returnTo ? { returnTo } : {}), idp: `${titleCase(domain)}AD`, login: user });
    }
    return false;
  }
}
