import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { AuthService } from '@logic-suite/shared/auth';
import { Connectivity, ConnectivityService } from '@logic-suite/shared/network';
import { retryOn504 } from '@logic-suite/shared/utils';
import { BehaviorSubject, Subscription, catchError, filter, firstValueFrom, map, of, switchMap, take } from 'rxjs';
import { EmployeeService } from '../../views/user/profile/employee.service';

export interface Policy {
  features: FeatureType;
  policies: Policies;
}
export type FeatureType = Partial<Record<Features, boolean>>;
export enum Features {
  // Booking
  HourlyBooking = 'hourlyBooking',
  RandomSeating = 'randomSeating',
  Unavailable = 'unavailable',
  // Social
  InvisibleMode = 'invisibleMode',
  Neighborhood = 'neighborhood',
  NeighborhoodColleagues = 'neighborhoodColleagues',
  SelfService = 'selfService',
  AutoBooking = 'autoBooking',
  ShowEmployeesInMap = 'showEmployeesInMap',
  Teams = 'teams',
  UserConsent = 'userConsent',
  // FLX Express
  LockerReservation = 'lockerReservation',
  Lunch = 'lunch',
  MeetingRoom = 'meetingRoom',
  Parking = 'parking',
  // Other
  GDRPErasure = 'gdprerasure',
  Onboarding = 'onboardingFlow',
  ShowNeighborhoodColors = 'showNeighborhoodColors',
  Statistics = 'statistics',
  StreetView = 'streetView',
}

export enum PolicyType {
  BookWorkdaysAhead = 'bookWorkdaysAhead',
  BookParkingDaysAhead = 'bookParkingDaysAhead',
  FlexLimit = 'flexLimit',
  WorkOnWeekends = 'workOnWeekends',
  NextWorkdayStartsAt = 'nextWorkdayStartsAt',
}
export interface Policies {
  [PolicyType.BookParkingDaysAhead]?: BookWorkdaysAhead;
  [PolicyType.BookWorkdaysAhead]?: BookWorkdaysAhead[];
  [PolicyType.FlexLimit]?: FlexLimit;
  [PolicyType.WorkOnWeekends]?: WorkOnWeekends;
  [PolicyType.NextWorkdayStartsAt]?: NextWorkdayStartsAt;
}
export interface BookWorkdaysAhead {
  bookDaysAhead: number | null; // Default
  number?: number; // Deprecated
  // If these properties are present, this is an override for the default policy
  allocatedBookDaysAhead?: number; // For everybody present in this neighborhood
  // The neighborhood
  entryID?: string;
  entryType?: string;
}
export interface FlexLimit {
  number: number;
  period: 'month' | 'week';
  unit: 'percent' | 'day';
}
export interface WorkOnWeekends {
  value: boolean;
}

export interface NextWorkdayStartsAt {
  value: number;
}

@Injectable({ providedIn: 'root' })
export class PolicyService implements OnDestroy {
  private features: FeatureType = {};
  private policies: Policies = {};
  subscriptions: Subscription[] = [];
  workingHours = [8, 9, 10, 11, 12, 13, 14, 15];

  private isLoaded$ = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private employee: EmployeeService,
    private conn: ConnectivityService,
  ) {
    this.subscriptions.push(
      this.auth.isLoggedIn$
        .pipe(
          filter(flag => flag === true),
          take(1),
          switchMap(() => this.employee.getCustomer$()),
          switchMap(() => this.loadPolicy()),
        )
        .subscribe(policy => this.policyLoaded(policy as Policy)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private policyLoaded(policy: Policy) {
    this.features = policy.features;
    this.policies = policy.policies;
    this.isLoaded$.next(true);
  }

  private loadPolicy() {
    this.isLoaded$.next(false);
    return this.http.get<Policy>('/api/flex/Policy').pipe(
      catchError(err => {
        // Request failed. If we are online, just return and die.
        if (this.conn.isOnline() === Connectivity.ONLINE) return err.pipe(retryOn504());
        // We are offline. Retry when gaining connectivity
        firstValueFrom(
          this.conn.connectivity$.pipe(
            filter(c => c === Connectivity.ONLINE),
            switchMap(() => this.loadPolicy()),
          ),
        ).then(policy => this.policyLoaded(policy as Policy));
        // ...and return a temporary dummy policy so that we may initialize app.
        return of({
          features: {
            hourlyBooking: false,
            randomSeating: false,
            unavailable: true,
            invisibleMode: true,
            neighborhood: false,
            neighborhoodColleagues: false,
            showEmployeesInMap: false,
            teams: false,
            lockerReservation: false,
            lunch: false,
            meetingRoom: false,
            parking: false,
            gdprerasure: true,
            onboardingFlow: false,
            statistics: false,
            streetView: false,
            selfService: false,
            autoBooking: false,
          },
          policies: {
            bookParkingDaysAhead: { bookDaysAhead: 0 },
            bookWorkdaysAhead: [{ bookDaysAhead: 0 }],
            flexLimit: { number: 40, unit: 'percent', period: 'month' },
            nextWorkdayStartsAt: { value: 17 },
            workOnWeekends: { value: false },
          },
        } as Policy);
      }),
    );
  }
  ensurePolicyLoaded() {
    return this.isLoaded$.pipe(
      filter(loaded => !!loaded),
      map(() => ({ features: this.features, policies: this.policies })),
    );
  }

  async hasFeatureAsync(feature: keyof FeatureType) {
    await firstValueFrom(this.ensurePolicyLoaded());
    return this.hasFeature(feature);
  }
  hasFeature(feature: keyof FeatureType) {
    return Object.keys(this.features).includes(feature as string) && this.features[feature] != false;
  }
  featureChanged(feature: keyof FeatureType) {
    return this.isLoaded$.pipe(
      filter(loaded => !!loaded),
      map(() => this.hasFeature(feature)),
    );
  }

  getPolicy(name: keyof Policies) {
    return this.policies[name];
  }

  getPolicyAsync(name: keyof Policies) {
    return new Promise((resolve, reject) => {
      firstValueFrom(this.ensurePolicyLoaded()).then(() => {
        resolve(this.getPolicy(name));
      });
    });
  }
  policyChanged(name: keyof Policies) {
    return this.isLoaded$.pipe(
      filter(loaded => !!loaded),
      map(() => this.getPolicy(name)),
    );
  }
}

@Injectable({ providedIn: 'root' })
export class PolicyResolver {
  constructor(private service: PolicyService) {}

  resolve() {
    return this.service.ensurePolicyLoaded().pipe(map(p => !!p));
  }
}
