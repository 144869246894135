import { Injectable, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';

export enum Connectivity {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

@Injectable({ providedIn: 'root' })
export class ConnectivityService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private onlineEvent: Observable<Event> = fromEvent(window, Connectivity.ONLINE);
  private offlineEvent: Observable<Event> = fromEvent(window, Connectivity.OFFLINE);
  private _connectivity$ = new BehaviorSubject<Connectivity>(
    window.navigator.onLine ? Connectivity.ONLINE : Connectivity.OFFLINE
  );
  readonly isOnline = toSignal(this._connectivity$);

  readonly connectivity$ = this._connectivity$.asObservable();

  constructor() {
    // Network connectivity detection
    this.subscriptions.push(this.onlineEvent.subscribe(e => this.setConnectivity(Connectivity.ONLINE)));
    this.subscriptions.push(this.offlineEvent.subscribe(e => this.setConnectivity(Connectivity.OFFLINE)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /*
   * Handles changes in connectivity
   */
  private setConnectivity(connection: Connectivity) {
    if (connection != this._connectivity$.value) {
      this._connectivity$.next(connection);
    }
  }
}
